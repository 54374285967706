<template>
  <b-row align-v="center" no-gutters>
    <b-col cols="10">
      <div>
        <v-select :value="value" :options="selectCategories" :filterable="false" label="name" @open="onOpen"
          @close="onClose" @search="(query) => (search = query)" :multiple="multiple"
          @input="(val) => $emit('input', val)" :readonly="readonly" :disabled="readonly" :clearable="false"
          class="base-action-select w-100">
          <template #list-footer>
            <li v-show="hasNextPage" ref="load" class="loader">
              {{ $t("LoadingMoreOptions") }}
            </li>
          </template>
        </v-select>
      </div>
    </b-col>
    <b-col cols="2">
      <b-button variant="primary" class="text-nowrap rounded-end d-inline-block base-select-add-button"
        @click="modalContactVisible = true">
        <feather-icon icon="PlusIcon" size="19" />
      </b-button>
    </b-col>
    <b-col>
      <ContactPersonCreationModal 
        :subclient="subclient" 
        v-model="modalContactVisible"
        @contact-created="handleContactCreated" 
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import ContactPersonCreationModal from "../modal/ContactPersonCreationModal.vue";
import usersApi from "@/api/users-api";

export default {
  components: {
    vSelect,
    ContactPersonCreationModal,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    subclient: {
      type: Number,
    },
  },
  data() {
    return {
      observer: null,
      currentPage: 1,
      lastPage: 0,
      pageLength: 10,
      search: "",
      selectCategories: [],
      selected: "",
      modalContactVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
    }),
    hasNextPage() {
      let output = true;
      if (this.lastPage === this.currentPage) {
        output = false;
      }
      if (this.selectCategories.length === 0) {
        output = false;
      }

      return output;
    },
  },
  watch: {
    async search() {
      this.currentPage = 1;
      this.selectCategories = [];
      await this.chargeData(true);
    },
  },
  async mounted() {
    await this.chargeData();
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    ...mapActions({
      list: "users/getListUsers",
    }),
    handleContactCreated(event) {
      this.modalContactVisible = false;
      this.chargeData(false, true); //recarga el componente
      this.$emit("input", event); //añades al selector
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async chargeData(ifSearch = false, clear = false) {
      if (clear) {
        this.currentPage = 1;
        this.selectCategories = [];
      }

      let cliente = this.currentClient ? this.currentClient.id : "";

      try {
        const response = await usersApi.list(
          this.currentCompany.id,
          cliente,
          this.subclient,
          this.currentPage,
          this.pageLength,
          this.search,
          ["client"]
        );

        if (ifSearch) {
          this.selectCategories = response.data.data;
        } else {
          // Filtrar elementos duplicados antes de concatenar
          const newCategories = response.data.data.filter(item => !this.selectCategories.some(category => category.id === item.id));
          this.selectCategories = this.selectCategories.concat(newCategories);
        }

        this.lastPage = response.data.meta.last_page;
      } catch (error) {
        console.log("ERROR", error);
      }
    },

    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.currentPage += 1;
        await this.chargeData();
        ul.scrollTop = scrollTop;
      }
    },
  },
};
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}

.form-control:disabled {
  background-color: #f7f5f0 !important;
}
</style>
